<template>
  <div id="app">
    <nav class="navbar navbar-expand">
      <a href="/" class="navbar-brand">
        <img style="max-width: 120px;" src="@/assets/logo-padrao-color.png" alt="">
      </a>
      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" /> Login
          </router-link>
        </li>
      </div>

      <div v-if="currentUser" class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Cupons</a>
          <ul class="dropdown-menu">
            <li>
              <router-link class="dropdown-item" :to="{ name: 'createCupom' }">
                Novo Cupom
              </router-link>
            </li>
            <li>
              <router-link class="dropdown-item" :to="{ name: 'home' }">
                Todos os Cupons
              </router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/">
                Cupons Utilizados
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> LogOut
          </a>
        </li>
      </div>
    </nav>

    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss">
  .navbar {
    background: #f5f5f5;
}
</style>
