import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const ListCupom = () => import("./components/ListCupom.vue")
const CreateCupom = () => import("./components/CreateCupom.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: ListCupom,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/cupom/novo",
    name: "createCupom",
    component: CreateCupom,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;